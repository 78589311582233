import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { IonicModule } from '@ionic/angular';

import { CancelridePageRoutingModule } from './cancelride-routing.module';

import { CancelridePage } from './cancelride.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
	TranslateModule,     
    CancelridePageRoutingModule
  ],
  declarations: [CancelridePage]
})
export class CancelridePageModule {}
