import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  posts:any;

   /* url:string="http://127.0.0.1:8000"; 
    urlServer:string = "http://tlcs.maresa.ma";*/
  url:string="https://taxicielcmt.gebanalysis.com"; 
  urlServer:string = "https://taxicielcmt.gebanalysis.com"

  constructor(private httpClient :HttpClient) { }


  upload(file):Observable<any> {
  
    // Create form data
    const formData = new FormData(); 
      
    // Store form name as "file" with file data
    formData.append("file", file, file.name);
      
    // Make http post request over api
    // with formData as req
    return this.httpClient.post(this.url+'/api/newchauffeur', formData)
 
}

addChauffeurPost(body:any){
  
  return this.httpClient.post(this.url+'/api/newchauffeur',body)
}
   

  getChauffeurs() {
    //return this.httpClient.get(this.url+'/api/chauffeurs?is_visible_mobile=true');
    return this.httpClient.get(this.url+'/api/chauffeurs?is_visible_mobile=true');
  }


 getmissionchaffeur(id){
    return this.httpClient.get(this.url+'/api/missions?chauffeur='+id+'&etatMission=10');
  }
  getmissionchaffeur2(id){
    return this.httpClient.get(this.url+'/api/missions?chauffeur='+id+'&etatMission=1');
  }
 
 

  getmissionchaffeursuccess(id){
    return this.httpClient.get(this.url+'/api/missions?chauffeur='+id+'&etatMission=4');
  }


  getChauffeurSignIn(id){

    return this.httpClient.get(this.url+'/api/chauffeurs/'+id);
  }

  getRatingChauffeur(id){
    return  this.httpClient.get(this.url+'/api/rating_drivers?chauffeur='+id);
  }
  

  loginChauffeur(body:any){
    return this.httpClient.post(this.url+'/api/chauffeur/login',body)
  }


  getSingleMission(id) {
    return this.httpClient.get(this.url+'/api/missions/'+id); 
  }


  updateEtatMission(body:any) {
    return this.httpClient.post(this.url+'/api/updateetatmission',body);
  }

  UpdateEtatMissionApproche(body:any) {
    return this.httpClient.post(this.url+'/api/updateetatmissionenapproche',body)
  }

  UpdateEtatMissionAnnuler(body:any) {
    return this.httpClient.post(this.url+'/api/updateetatmissioneannuler',body)
  }

  UpdateEtatMissionPrisEnCharge(body:any) {
    return this.httpClient.post(this.url+'/api/updateetatpriseencharge',body)
  }

  UpdateEtatMissionDepose(body:any) {
    return this.httpClient.post(this.url+'/api/updateetatdepose',body)
  }



  cancelDriverMission(body:any){

    return this.httpClient.post(this.url+'/api/annulationmission',body)
  }




}
