import { Component, OnInit } from '@angular/core';
import { NavParams,ModalController } from '@ionic/angular'; 

import { NavController, ToastController } from '@ionic/angular';

import { DataService } from 'src/app/services/data.service';
 
import { Router,ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Storage } from '@ionic/storage';
 
@Component({
  selector: 'app-riderinfo',
  templateUrl: './riderinfo.page.html',
  styleUrls: ['./riderinfo.page.scss'],
})
export class RiderinfoPage implements OnInit {

  mission:any;
  mission_id:any;

  constructor(private navParams: NavParams,private storage: Storage, private toastCtrl:ToastController, private httpClient : HttpClient, private dataService: DataService,private navCtrl: NavController, private route: Router,private activatedRoute :ActivatedRoute,private modalController: ModalController) { }

  ngOnInit() {

    
    this.mission_id = this.navParams.get("mission_id");

     //alert(this.mission_id );
    console.log("id mission "+this.mission_id);

    this.storage.get('session').then((val) => {

      console.log('user', val);
      if(val == null){
        this.navCtrl.navigateRoot(['./login']);

      }


  this.dataService.getSingleMission(this.mission_id).subscribe((data:any)=>{
        this.mission = data;
        console.log(this.mission);

      });
      });

  }

   
dismiss(){
   this.modalController.dismiss();
 }

}
