import { Component, OnInit } from '@angular/core';
import { NavController,ToastController } from '@ionic/angular';
import { NavParams,ModalController } from '@ionic/angular';
import { Router,ActivatedRoute} from '@angular/router';

import { DataService } from 'src/app/services/data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';




declare var $ : any;

@Component({
  selector: 'app-cancelride',
  templateUrl: './cancelride.page.html',
  styleUrls: ['./cancelride.page.scss'],
})



export class CancelridePage implements OnInit {
   mission_id:any;
   pax:any;
   val:any;
   today:any;



  constructor(private storage: Storage, private toastCtrl:ToastController, private httpClient : HttpClient, private dataService: DataService,private navCtrl: NavController,private navParams: NavParams,private modalController: ModalController, private router: Router,private activatedRoute: ActivatedRoute ) { }



  ngOnInit() {

        
    this.mission_id = this.navParams.get("mission_id");
    console.log("la mission" + this.mission_id + "pax" + this.pax);
   
   if(this.mission_id == null || this.mission_id == '') {
       this.router.navigate(['./tabs/account']);   
    } 

    this.storage.get('session').then((val) => {

      console.log('user', val);
      if(val == null){
        this.navCtrl.navigateRoot(['./login']);

      }
    });
 


  }


dismiss(){
   this.modalController.dismiss();
 }


  motif1(){
    this.modalController.dismiss();

    this.storage.get('session').then((val) => {

      console.log('user', val.chauffeur);
      if(val == null){
        this.navCtrl.navigateRoot(['./login']);
        
      }else{

        const now = new Date();
        
        //alert($(".value1 h3").text() + "la mission" + this.mission_id + "pax" + this.pax + "chauffeur" + val.chauffeur + "date now " + this.today);

        this.dataService.cancelDriverMission({
          "chauffeur": val.chauffeur ,
          "mission": this.mission_id,
          "date":  now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(),
          "motification" :$(".value1 h3").text(),
          "pax" : this.pax
            }).subscribe(
         (data:any)=>{
          if(data.Success="OK"){
          this.toastCtrl.create({message :"la mission est Annulé ",position:"top",duration:6000}).then(t=>t.present());
           }else{
            this.toastCtrl.create({message :"erreur ",position:"top",duration:6000}).then(t=>t.present());
         
           }
         
            }
         );

        /* this.dataService.UpdateEtatMissionAnnuler({
          "id": this.mission_id
            }).subscribe(
         (data:any)=>{
          //this.toastCtrl.create({message :"Etat de mission est annulé ",position:"top",duration:6000}).then(t=>t.present());
        console.log(data); 
         } );
   */

         this.navCtrl.navigateRoot(['./tabs/account']);
 
      }

    });


  }



  motif2(){
    this.modalController.dismiss();
    this.storage.get('session').then((val) => {

      console.log('user', val.chauffeur);
      if(val == null){
        this.navCtrl.navigateRoot(['./login']);

      }else{
        const now = new Date();
        this.today = now.toISOString();

        this.dataService.cancelDriverMission({
          "chauffeur": val.chauffeur ,
          "mission": this.mission_id,
          "date":  now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(),
          "motification" :$(".value2 h3").text(),
          "pax" : this.pax
            }).subscribe(
         (data:any)=>{
       
          this.toastCtrl.create({message :"la mission est Annulé ",position:"top",duration:6000}).then(t=>t.present());
           console.log(data);
          
         
            }
         );
         this.navCtrl.navigateRoot(['./tabs/account']);
       // alert($(".value1 h3").text() + "la mission" + this.mission_id + "pax" + this.pax + "chauffeur" + val.chauffeur + "date now " + this.today);

      }

    });
  
    }



  motif3(){
    this.modalController.dismiss();
  this.storage.get('session').then((val) => {

    console.log('user', val.chauffeur);
    if(val == null){
      this.navCtrl.navigateRoot(['./login']);

    }else{
      const now = new Date();
      this.today = now.toISOString();

      this.dataService.cancelDriverMission({
        "chauffeur": val.chauffeur ,
        "mission": this.mission_id,
        "date":  now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(),
        "motification" :$(".value3 h3").text(),
        "pax" : this.pax
          }).subscribe(
       (data:any)=>{
     
        this.toastCtrl.create({message :"la mission est Annulé ",position:"top",duration:6000}).then(t=>t.present());
         console.log(data);
        
       
          }
       );
       this.navCtrl.navigateRoot(['./tabs/account']);
      //alert($(".value1 h3").text() + "la mission" + this.mission_id + "pax" + this.pax + "chauffeur" + val.chauffeur + "date now " + this.today);

    }

  });

  }



  motif4(){
    	this.modalController.dismiss();
    this.storage.get('session').then((val) => {

      console.log('user', val.chauffeur);
      if(val == null){
        this.navCtrl.navigateRoot(['./login']);

      }else{

        const now = new Date();
        this.today = now.toISOString();
        this.dataService.cancelDriverMission({
          "chauffeur": val.chauffeur,
          "mission": this.mission_id,
          "date":  now.getFullYear() + '-' + now.getMonth() + '-' + now.getDate(),
          "motification" :$(".value4 h3").text(),
          "pax" : this.pax
            }).subscribe(
         (data:any)=>{
       
          this.toastCtrl.create({message :"la mission est Annulé ",position:"top",duration:6000}).then(t=>t.present());
           console.log(data);
           this.navCtrl.navigateRoot(['./tabs/account']);
         
            }
         );
       // alert($(".value1 h3").text() + "la mission" + this.mission_id + "pax" + this.pax + "chauffeur" + val.chauffeur + "date now " + this.today);

      }

    });

   }

	
 home() {
	this.modalController.dismiss();
    this.navCtrl.navigateRoot(['./tabs']);
  }
}
 