import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
   {
    path: '', redirectTo: 'login',   pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./sign-up/sign-up.module').then( m => m.SignUpPageModule)
  },
  {
    path: 'verification',
    loadChildren: () => import('./verification/verification.module').then( m => m.VerificationPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  
  {
    path: 'accept-rider',
    loadChildren: () => import('./accept-rider/accept-rider.module').then( m => m.AcceptRiderPageModule)
  },
  
  {
    path: 'riderinfo',
    loadChildren: () => import('./riderinfo/riderinfo.module').then( m => m.RiderinfoPageModule)
  },
  {
    path: 'cancelride',
    loadChildren: () => import('./cancelride/cancelride.module').then( m => m.CancelridePageModule)
  },
 
  {
    path: 'location-selector',
    loadChildren: () => import('./location-selector/location-selector.module').then( m => m.LocationSelectorPageModule)
  },
 
  {
    path: 'fare-rate',
    loadChildren: () => import('./fare-rate/fare-rate.module').then( m => m.FareRatePageModule)
  },
  {
    path: 'ratings',
    loadChildren: () => import('./ratings/ratings.module').then( m => m.RatingsPageModule)
  },
  {
    path: 'earnings',
    loadChildren: () => import('./earnings/earnings.module').then( m => m.EarningsPageModule)
  }, 
  {
    path: 'my-trips',
    loadChildren: () => import('./my-trips/my-trips.module').then( m => m.MyTripsPageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'add-money-to-bank',
    loadChildren: () => import('./add-money-to-bank/add-money-to-bank.module').then( m => m.AddMoneyToBankPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('./documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'my-profile',
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'start-trip',
    loadChildren: () => import('./start-trip/start-trip.module').then( m => m.StartTripPageModule)
  },
  {
    path: 'change-language',
    loadChildren: () => import('./change-language/change-language.module').then( m => m.ChangeLanguagePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
