import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
 

import { RiderinfoPageModule } from './riderinfo/riderinfo.module';
import { CancelridePageModule } from './cancelride/cancelride.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_CONFIG, BaseAppConfig } from './app.config';
import { VtPopupPageModule } from './vt-popup/vt-popup.module';
import { BuyappalertPageModule } from './buyappalert/buyappalert.module';
import { IonicStorageModule } from '@ionic/storage';
import { Camera } from '@ionic-native/Camera/ngx';
import { File } from '@ionic-native/File/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { SignUpPage } from './sign-up/sign-up.page';
import { FCM } from '@awesome-cordova-plugins/fcm/ngx';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { Facebook } from '@ionic-native/facebook/ngx';


export const firebaseConfig = {
  apiKey: "AIzaSyCjtUeX4hNEg3MClMvDgx1Py5BLxSCwJ6E",
  authDomain: "cmt-driver.firebaseapp.com",
  projectId: "cmt-driver",
  storageBucket: "cmt-driver.appspot.com",
  messagingSenderId: "207620741352",
  appId: "1:207620741352:web:7b04923a5276c9c998515d"
};


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent
    ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule,
    RiderinfoPageModule,
    CancelridePageModule,
    VtPopupPageModule,
    BuyappalertPageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    StatusBar,
    Facebook,
    SplashScreen,
    { provide: APP_CONFIG, useValue: BaseAppConfig },
    Camera,
    File,
    FCM,
    WebView,
    FilePath,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
